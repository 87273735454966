import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'removePrefix',
})
export class RemovePrefixPipe implements PipeTransform {
    transform(value: string, prefix: string): string {
        value = value.replace(prefix, '');
        return value.charAt(0).toUpperCase() + value.substring(1);
    }
}
