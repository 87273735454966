import {Pipe, PipeTransform} from '@angular/core';
import {SlidePanelDefinitions} from '../../definitions/definitions';
import {SlidePanelDefinition} from '../../definitions/definitions-base';

@Pipe({name: 'slidePanelPath'})
export class SlidePanelPathPipe implements PipeTransform {
    transform(definitionKey: keyof typeof SlidePanelDefinitions, fnArg?: number | number[]) {
        const def = SlidePanelDefinitions[definitionKey] as SlidePanelDefinition;
        return Array.isArray(fnArg) ? def.getPath(...fnArg) : def.getPath(fnArg);
    }
}
