import {Component, Input} from '@angular/core';
import {ConfigService} from '../../../@core/config.service';

@Component({
    selector: ' app-logo',
    template: `
        <a *ngIf="clickable; else span"
           class="logo {{variant}}"
           [class.washSense]="host === 'WASHSENSE'"
           [class.spaced]="spaced"
           routerLink="/">
            <ng-container *ngTemplateOutlet="inner"></ng-container>
        </a>
        <ng-template #span>
            <span class="logo {{variant}}"
                  [class.washSense]="host === 'WASHSENSE'"
                  [class.spaced]="spaced">
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </span>
        </ng-template>
        <ng-template #inner [ngSwitch]="host">
            <ng-container *ngSwitchCase="'WASHSENSE'">
                w<strong>s</strong>
                <div class="label">wash<strong>sense</strong></div>
            </ng-container>
            <img alt="helix logo" src="/assets/images/helix-logo.png" *ngSwitchCase="'HELIX'">
            <img alt="ccmdaco logo" src="/assets/images/ccmdaco-logo.png" *ngSwitchCase="'CCMDACO'">
            <img alt="guardian logo" src="/assets/images/guardian-logo.png" *ngSwitchCase="'GUARDIAN'">
            <img alt="solutions lab logo" src="/assets/images/solutions-lab-logo.png" *ngSwitchCase="'SOLUTIONS_LAB'">
            <img alt="corelab logo" src="/assets/images/core-analytics-logo.png" *ngSwitchCase="'CORELAB'">
            <img alt="arsana logo" src="/assets/images/arsana-logo.svg" *ngSwitchDefault>
        </ng-template>
    `,
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
    @Input() variant: 'inline' | 'block' = 'inline';
    @Input() clickable = false;
    @Input() spaced = false;

    host = this.config.host;

    constructor(private config: ConfigService) {
    }
}
