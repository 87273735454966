import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IllustrationComponent} from './illustration.component';
import {IllustrationMessageComponent} from './illustration-message.component';
import {IllustrationTreeComponent} from './illustration-tree.component';
import {TailComponent} from './tail.component';

const COMPONENTS = [
    IllustrationComponent,
    IllustrationTreeComponent,
    IllustrationMessageComponent,
    TailComponent,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ...COMPONENTS,
    ],
    exports: [
        ...COMPONENTS,
    ],
})

export class IllustrationModule {
}
