import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {animationFrameScheduler, fromEvent, interval} from 'rxjs';
import {UnsubscribeComponent} from '../../../@core/fc-component';
import {takeUntil} from 'rxjs/operators';
import {contextColors} from '../../theme-colors';
import {hexToRgb} from '../../../utils/color.utils';
const Anim = { //animation settings
    duration: 3000,
    interval: 10,
    stepUnit: .5,
    currUnit: 0,
};

@Component({
    selector: 'app-gradient-bg',
    template: `
        <canvas #canvas></canvas>
    `,
    styleUrls: ['./gradient-bg.component.scss'],
})
export class GradientBgComponent extends UnsubscribeComponent implements AfterViewInit {
    stopAColor = [
        hexToRgb(contextColors.brand_2),
        hexToRgb(contextColors.brand_3),
    ];
    stopBColor = [
        hexToRgb(contextColors.brand_3),
        hexToRgb(contextColors.brand_2),
    ];

    @ViewChild('canvas', {static: true}) canvasEl: ElementRef;

    canvas;
    angle = 45;
    ctx;
    width: number;
    height: number;
    radius: number;
    colorStops = [];
    currentStop = 0;
    gradient;

    ngAfterViewInit() {
        this.canvas = this.canvasEl.nativeElement;
        this.ctx = this.canvas.getContext('2d');

        this.updateUI();
        fromEvent(window, 'resize').pipe(takeUntil(this.destroy$)).subscribe(() => this.updateUI());

        interval(0, animationFrameScheduler).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.updateStops();
            this.draw();
        });
    }

    addStop(pos, colors) {
        this.colorStops.push({pos, colors, currColor: null});
    }

    updateStops() { //interpolate colors of stops
        const steps = Anim.duration / Anim.interval,
            step_u = Anim.stepUnit / steps,
            stopsLength = this.colorStops[0].colors.length - 1;

        this.colorStops.forEach(stop => {
            const startColor = stop.colors[this.currentStop];//get stop 1 color
            let endColor;

            if (this.currentStop < stopsLength) { //get stop 2 color, go to first if at last stop
                endColor = stop.colors[this.currentStop + 1];
            } else {
                endColor = stop.colors[0];
            }

            //interpolate both stop 1&2 colors to get new color based on animaiton unit
            const r = Math.floor(this.lerp(startColor.r, endColor.r, Anim.currUnit));
            const g = Math.floor(this.lerp(startColor.g, endColor.g, Anim.currUnit));
            const b = Math.floor(this.lerp(startColor.b, endColor.b, Anim.currUnit));

            stop.currColor = `rgb(${r},${g},${b})`;
        });

        // update current stop and animation units if interpolaiton is complete
        if (Anim.currUnit >= 1.0) {
            Anim.currUnit = 0;
            if (this.currentStop < stopsLength) {
                this.currentStop++;
            } else {
                this.currentStop = 0;
            }
        }

        Anim.currUnit += step_u; //increment animation unit

        this.angle += .125;
    }

    draw() {
        const rad = this.angle * 0.0174533,
            dy = Math.sin(rad) * this.radius,
            dx = Math.cos(rad) * this.radius;

        // const gradient = this.ctx.createLinearGradient(this.width / 2 - dx, this.width / 2 + dx, this.height / 2 - dy, this.height / 2 + dy);
        const gradient = this.ctx.createRadialGradient(this.width / 2 - dx, this.height / 2 - dy, this.radius, this.width / 2 + dx, this.height / 2 + dy, this.radius);
        this.colorStops.forEach(stop => gradient.addColorStop(stop.pos, stop.currColor));

        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.fillStyle = gradient;
        this.ctx.fillRect(0, 0, this.width, this.height);
    }

    updateUI() {
        const bcr = document.documentElement.getBoundingClientRect();

        this.canvas.width = bcr.width;
        this.canvas.height = bcr.height;

        this.width = this.canvas.width;
        this.height = this.canvas.height;
        this.radius = Math.sqrt((Math.pow(this.width, 2) + Math.pow(this.height, 2)) / 4);

        this.addStop(0, this.stopAColor);
        this.addStop(1, this.stopBColor);
    }

    lerp(a, b, u) { //interpolation
        return (1 - u) * a + u * b;
    }
}
