import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TopColorCardComponent} from './components/top-color-card/top-color-card.component';
import {IllustrationModule} from '../@shared/illustration/illustration.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PeriodPipe} from './pipes/period.pipe';
import {ClearPipe} from './pipes/clear.pipe';
import {HumanNamePipe} from './pipes/humanName.pipe';
import {GradientBgComponent} from './components/gradient-bg/gradient-bg.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {ConstantPipe} from './pipes/constant.pipe';
import {ColorPipe} from './pipes/color.pipe';
import {NgsContenteditableModule} from '@ng-stack/contenteditable';
import {PhoneFormatPipe} from './pipes/phone-format.pipe';
import {SsnFormatPipe} from './pipes/ssn-format.pipe';
import {SwitchUserModalComponent} from './components/switch-user-modal/switch-user-modal.component';
import {LogoComponent} from './components/logo/logo.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {ToggleComponent} from './components/toggle/toggle.component';
import {CardComponent} from './components/card/card.component';
import {SectionHeadComponent} from './components/section-head/section-head.component';
import {HintComponent} from './components/hint/hint.component';
import {ActionIconComponent} from './components/action-icon/action-icon.component';
import {UserIconComponent} from './components/user-icon/user-icon.component';
import {CapitalizePipe} from './pipes/capitalize.pipe';
import {TimingPipe} from './pipes/timing.pipe';
import {RoundPipe} from './pipes/round.pipe';
import {PluralPipe} from './pipes/plural.pipe';
import {HumanReadablePipe} from './pipes/human-readable.pipe';
import {TzDatePipe} from './pipes/tz-date.pipe';
import {IcdCodePipe} from './pipes/icd-code.pipe';
import {JoinPipe} from './pipes/join.pipe';
import {LimitPipe} from './pipes/limit.pipe';
import {SimpleDatePipe} from './pipes/simple-date.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {UserBadgesComponent} from './components/user-badges/user-badges.component';
import {PassedDaysPipe} from './pipes/passed-days.pipe';
import {RemovePrefixPipe} from './pipes/remove-prefix.pipe';
import {ContentDefinitionPipe} from './pipes/content-definition.pipe';
import {FaModule} from './components/fa/fa.module';
import {UnderProcessingModule} from '../portal/@portal-theme/components/under-processing/under-processing.module';
import {FileIconComponent} from './components/file-icon/file-icon.component';
import {ErrorMessageComponent} from '../portal/@portal-shared/error-message/error-message.component';
import {SlidePanelPathPipe} from './pipes/slide-panel-path.pipe';

const BASE_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgsContenteditableModule,
    FaModule,
    UnderProcessingModule,
];

const MODULES = [
    IllustrationModule,
];

const COMPONENTS = [
    LogoComponent,
    SpinnerComponent,
    ToggleComponent,
    CardComponent,
    TopColorCardComponent,
    SectionHeadComponent,
    HintComponent,
    ActionIconComponent,
    GradientBgComponent,
    NotFoundComponent,
    UserIconComponent,
    SwitchUserModalComponent,
    UserBadgesComponent,
    FileIconComponent,
    ErrorMessageComponent,
];

const DIRECTIVES = [];

const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    HumanReadablePipe,
    TzDatePipe,
    PeriodPipe,
    IcdCodePipe,
    JoinPipe,
    LimitPipe,
    HumanNamePipe,
    ClearPipe,
    SimpleDatePipe,
    SafePipe,
    ConstantPipe,
    ColorPipe,
    PhoneFormatPipe,
    SsnFormatPipe,
    PassedDaysPipe,
    RemovePrefixPipe,
    ContentDefinitionPipe,
    SlidePanelPathPipe,
];

const THEME_PROVIDERS = [
    DatePipe,
    TzDatePipe,
    HumanReadablePipe,
];

@NgModule({
    imports: [
        ...BASE_MODULES,
        ...MODULES,
    ],
    declarations: [
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
    ],
    exports: [
        ...BASE_MODULES,
        ...MODULES,
        ...COMPONENTS,
        ...PIPES,
        ...DIRECTIVES,
    ],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [...THEME_PROVIDERS],
        };
    }
}
