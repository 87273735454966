import {Component} from '@angular/core';

@Component({
    selector: 'app-not-found',
    template: `
        <div class="error-status">
            <div class="code">404</div>
            <div class="text">Not found</div>
            <a class="btn btn-lg btn-outline-primary mt-5" routerLink="/">Jump to home</a>
        </div>
    `,
})
export class NotFoundComponent {
}
