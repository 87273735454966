import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {
    FileTypeDisplayConfiguration,
    getFileTypeIcon,
} from '../../../utils/file.utils';

@Component({
    selector: 'app-file-icon',
    templateUrl: './file-icon.component.html',
})
export class FileIconComponent implements OnInit {
    @Input() mimeType: string;

    fileTypeDisplay: FileTypeDisplayConfiguration;

    @HostBinding('class.d-flex') flex = true;
    @HostBinding('class.flex-nowrap') flexNoWrap = true;
    @HostBinding('style.gap.em') gap = .3;

    ngOnInit() {
        this.fileTypeDisplay = getFileTypeIcon(this.mimeType);
    }
}
