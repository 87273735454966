import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnderProcessingComponent} from './under-processing.component';
import {FaModule} from '../../../../@theme/components/fa/fa.module';

@NgModule({
    declarations: [UnderProcessingComponent],
    imports: [
        CommonModule,
        FaModule,
    ],
    exports: [UnderProcessingComponent],
})
export class UnderProcessingModule {
}
