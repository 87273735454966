import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'clear'})
export class ClearPipe implements PipeTransform {
    transform(input: string, clearWith: string, caseSensitive = false): string {
        const inputWord = caseSensitive ? input : input.toLocaleLowerCase();
        const inputClear = caseSensitive ? clearWith : clearWith.toLocaleLowerCase();
        const i = inputWord.indexOf(inputClear);
        return i > -1 ? (input.slice(0, i) + input.slice(i + inputClear.length)).trim().replace('  ', ' ') : input;
    }
}
