import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-section-head',
    host: {'class': 'section-head'},
    template: `
        <h3 class="title">{{title}}</h3>
        <span class="badge badge-{{badgeColor}}" *ngIf="badge">{{badge}}</span>
        <div class="add-on">
            <ng-content></ng-content>
        </div>
    `,
})
export class SectionHeadComponent {
    @Input() title: string;
    @Input() badge: number | string;
    @Input() badgeColor = 'secondary';
}
