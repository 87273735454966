import {Component, ContentChild, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-top-color-card',
    template: `
        <div class="section-head">
            <h3 class="title" [ngClass]="unmuteTitle && 'text-unmuted'">{{title}} <small *ngIf="subTitle">({{subTitle}})</small></h3>
            <span *ngIf="badgeNumber" class="badge badge-{{colorClass}}">{{badgeNumber}}</span>
            <div class="add-on">
                <div [ngTemplateOutlet]="addOn" class="add-on"></div>
            </div>
        </div>
        <div class="flex-grow-1 p-4 mb-3 bg-white raised-light border-{{colorClass}} top-color-card">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./top-color-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TopColorCardComponent {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() badgeNumber: number;
    @Input() colorClass: string;
    @Input() unmuteTitle: boolean;

    @ContentChild('addOn') addOn;
}
